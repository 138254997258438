import { ChangeEvent, Fragment, ReactNode } from 'react';
import {
    Body,
    Box,
    ColorScheme,
    ColorSchemeProvider,
    FilterCheckbox,
    Flex,
    Grid,
    ListDivider,
    Note,
    Pagination,
    SearchInput,
    SearchListItem,
    SkeletonListItem,
    Stack,
    Tag,
} from 'designsystem';
import useGetApiImageProps from '../../hooks/useGetApiImageProps';
import Link from 'next/link';
import {
    createEnumArrayParam,
    createEnumParam,
    NumberParam,
    StringParam,
    useQueryParams,
    withDefault,
} from 'use-query-params';
import getCollectionFromEditionType from '../../lib/getCollectionFromEditionType';
import { EditionType } from '../../constants/editionTypes';
import gtm from '../../lib/gtm';
import { FormattedMessage, useIntl } from 'react-intl';
import { slugifyTitle } from '../../index';
import { useDebounce } from 'use-debounce';
import getResultRange from '../../lib/getResultRange';
import { GroupTypeEnum, SearchGlobalPageTypeEnum, useSearchGlobalQuery } from '../../gql/global';
import getTitleFromEditionType from '../../lib/getTitleFromEditionType';
import styled from '@emotion/styled';

const pillarTypeFilters = [
    {
        children: <FormattedMessage defaultMessage="Alle websites" id="search-all-pillars" />,
        value: '',
    },
    {
        children: <FormattedMessage defaultMessage="Festival" />,
        value: GroupTypeEnum.Festival,
    },
    {
        children: <FormattedMessage defaultMessage="Professionals" />,
        value: GroupTypeEnum.Professional,
    },
    {
        children: <FormattedMessage defaultMessage="Institute" />,
        value: GroupTypeEnum.Institute,
    },
];
const pageTypeFilters = [
    {
        children: <FormattedMessage defaultMessage="Bekijk alles" id="search-view-all" />,
        value: null,
    },
    {
        children: <FormattedMessage defaultMessage="Informatie" />,
        value: SearchGlobalPageTypeEnum.Information,
    },
    {
        children: <FormattedMessage defaultMessage="Programma" />,
        value: SearchGlobalPageTypeEnum.Program,
    },
    {
        children: <FormattedMessage defaultMessage="Nieuws" />,
        value: SearchGlobalPageTypeEnum.News,
    },
    {
        children: <FormattedMessage defaultMessage="Film" />,
        value: SearchGlobalPageTypeEnum.Film,
    },
];

const allPageTypes = pageTypeFilters.map(filter => filter.value).filter(Boolean);
const allPillarTypes = pillarTypeFilters.map(filter => filter.value).filter(Boolean);
const PillarParam = withDefault(createEnumParam(allPillarTypes), null);
const PageTypeParam = withDefault<SearchGlobalPageTypeEnum[] | null, null>(createEnumArrayParam(allPageTypes), null);
const PageParam = withDefault(NumberParam, 1);

interface Props {
    tiles: ReactNode[];
}

const ITEMS_PER_PAGE = 10;

const getPillarUrl = (group: string) => {
    switch (group) {
        case 'festival':
            return process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL;
        case 'professionals':
        case 'professional':
            return process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS;
        case 'institute':
            return process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE;
        default:
            return '';
    }
};

const SearchTemplate = ({ tiles }: Props) => {
    const getImageProps = useGetApiImageProps();
    const { formatMessage } = useIntl();
    const [query, setQuery] = useQueryParams({
        q: StringParam,
        group: PillarParam,
        pageTypes: PageTypeParam,
        page: PageParam,
    });
    const { page, q, group, pageTypes } = query;

    const [debouncedQuery] = useDebounce(q, 400);
    const offset = (page - 1) * ITEMS_PER_PAGE;

    const { data, isFetching } = useSearchGlobalQuery({
        query: debouncedQuery,
        filters: {
            pageTypes: pageTypes ?? allPageTypes,
            group,
        },
        limit: ITEMS_PER_PAGE,
        offset,
    });
    const hits = data?.searchGlobal?.hits;
    const totalHits = data?.searchGlobal?.totalHits;
    const hasHits = hits?.length > 0;

    const hasHitsOrIsFetching = (then: number | string, or: number | string): number | string => {
        if (hasHits || isFetching) {
            return then;
        }
        return or;
    };

    return (
        <>
            <Box maxW={[null, null, '476px']}>
                <SearchInput
                    options={pillarTypeFilters}
                    filterValue={group}
                    placeholder={formatMessage({
                        defaultMessage: 'Zoeken',
                    })}
                    defaultValue={q}
                    onChange={e => {
                        gtm.event('search', {
                            search_term: e.target.value,
                        });
                        setQuery({ q: e.target.value, page: 1 });
                    }}
                    onClear={() => setQuery({ q: '', page: 1 })}
                    onFilterChange={e => setQuery({ group: e.target.value, page: 1 })}
                />
            </Box>
            <Flex pt={7} flexWrap="wrap" gap={2}>
                {pageTypeFilters.map(({ value, children }) => (
                    <StyledFilterCheckbox
                        key={value}
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setQuery(current => {
                                if (value === null) {
                                    return { pageTypes: null };
                                }

                                if (e.target.checked) {
                                    return {
                                        pageTypes: current.pageTypes === null ? [value] : [...current.pageTypes, value],
                                    };
                                }
                                return { pageTypes: current?.pageTypes.filter(val => val !== value) ?? [value] };
                            });
                        }}
                        isChecked={pageTypes?.includes(value) || (value === null && pageTypes == null)}
                        hideIcon
                    >
                        {children}
                    </StyledFilterCheckbox>
                ))}
            </Flex>

            {hasHits && (
                <Body mt={[5, null, 13]} mb={[5, 4]}>
                    {formatMessage(
                        {
                            defaultMessage: '{resultRange} van {totalHits}',
                        },
                        {
                            resultRange: getResultRange(offset, data?.searchGlobal.totalHits, ITEMS_PER_PAGE),
                            totalHits: data?.searchGlobal.totalHits,
                        }
                    )}
                </Body>
            )}

            <Grid mt={[!hasHits && isFetching ? 5 : 0, null, null, !hasHits && isFetching ? 13 : 0]}>
                <Box
                    gridColumnStart={1}
                    gridColumnEnd={[6, null, hasHitsOrIsFetching(8, 13), hasHitsOrIsFetching(9, 13)]}
                >
                    {hasHits && (
                        <Stack spacing={[6, null, 8]} divider={<ListDivider />}>
                            {hits.map(hit => (
                                <Fragment key={hit.id}>
                                    <>
                                        {hit.__typename === 'Film' && (
                                            <ColorSchemeProvider colorScheme={hit.group.toLowerCase() as ColorScheme}>
                                                <Link
                                                    href={`${getPillarUrl(
                                                        hit.group.toLowerCase()
                                                    )}/${getCollectionFromEditionType(
                                                        hit.edition.editionType.description as EditionType
                                                    )}/${hit.id}/${slugifyTitle(hit.fullPreferredTitle)}`}
                                                    prefetch={false}
                                                >
                                                    <SearchListItem
                                                        title={hit.fullPreferredTitle}
                                                        type={
                                                            <Stack direction="row" spacing={2}>
                                                                <GroupTag size="s">
                                                                    {
                                                                        pillarTypeFilters?.find(
                                                                            filter =>
                                                                                filter.value === hit.group.toUpperCase()
                                                                        )?.children
                                                                    }
                                                                </GroupTag>
                                                                <Tag size="s">
                                                                    {getTitleFromEditionType(
                                                                        hit.edition.editionType
                                                                            .description as EditionType
                                                                    )}
                                                                </Tag>
                                                            </Stack>
                                                        }
                                                        description={
                                                            hit.intro?.translation ||
                                                            hit.logline ||
                                                            hit.docschoolOneliner?.translation ||
                                                            hit.description?.translation
                                                        }
                                                        image={getImageProps(
                                                            hit.publications.favoriteImage,
                                                            hit.fullPreferredTitle
                                                        )}
                                                    />
                                                </Link>
                                            </ColorSchemeProvider>
                                        )}
                                        {hit.__typename === 'Page' && (
                                            <ColorSchemeProvider colorScheme={hit.group.toLowerCase() as ColorScheme}>
                                                <Link
                                                    href={
                                                        getPillarUrl(hit.group.toLowerCase()) +
                                                        new URL(hit.url).pathname
                                                    }
                                                    prefetch={false}
                                                >
                                                    <SearchListItem
                                                        title={hit.title}
                                                        type={
                                                            <Stack direction="row" spacing={2}>
                                                                <GroupTag size="s">
                                                                    {
                                                                        pillarTypeFilters?.find(
                                                                            filter =>
                                                                                filter.value === hit.group.toUpperCase()
                                                                        )?.children
                                                                    }
                                                                </GroupTag>
                                                                <Tag size="s">
                                                                    {pageTypeFilters?.find(
                                                                        filter => filter.value === hit.pageType
                                                                    )?.children ?? hit.pageType}
                                                                </Tag>
                                                            </Stack>
                                                        }
                                                        description={hit.introText}
                                                        image={getImageProps(hit.thumbnail, hit.title)}
                                                        breadcrumbs={{
                                                            breadcrumbs: hit.breadcrumbs?.map(({ title }) => ({
                                                                title,
                                                            })),
                                                            homeBreadcrumb: { title: '' },
                                                        }}
                                                    />
                                                </Link>
                                            </ColorSchemeProvider>
                                        )}
                                        {hit.__typename === 'Composition' && (
                                            <ColorSchemeProvider colorScheme={hit.group.toLowerCase() as ColorScheme}>
                                                <Link
                                                    href={`${getPillarUrl(hit.group.toLowerCase())}/composition/${
                                                        hit.id
                                                    }/${slugifyTitle(hit.fullTitle)}`}
                                                >
                                                    <SearchListItem
                                                        title={hit.fullTitle}
                                                        type={
                                                            <Stack direction="row" spacing={2}>
                                                                <GroupTag size="s">
                                                                    {
                                                                        pillarTypeFilters?.find(
                                                                            filter =>
                                                                                filter.value === hit.group.toUpperCase()
                                                                        )?.children
                                                                    }
                                                                </GroupTag>
                                                                <Tag size="s">
                                                                    <FormattedMessage defaultMessage="Programma" />
                                                                </Tag>
                                                            </Stack>
                                                        }
                                                        description={hit.description?.translation}
                                                        image={getImageProps(
                                                            hit.publications.favoriteImage ??
                                                                hit.publications.stills?.[0],
                                                            hit.fullTitle
                                                        )}
                                                    />
                                                </Link>
                                            </ColorSchemeProvider>
                                        )}
                                        {hit.__typename === 'EditionSection' && (
                                            <ColorSchemeProvider colorScheme={hit.group.toLowerCase() as ColorScheme}>
                                                <Link
                                                    href={`${getPillarUrl(hit.group.toLowerCase())}/section/${
                                                        hit.id
                                                    }/${slugifyTitle(hit.name)}`}
                                                >
                                                    <SearchListItem
                                                        title={hit.name}
                                                        type={
                                                            <Stack direction="row" spacing={2}>
                                                                <GroupTag size="s">
                                                                    {
                                                                        pillarTypeFilters?.find(
                                                                            filter =>
                                                                                filter.value === hit.group.toUpperCase()
                                                                        )?.children
                                                                    }
                                                                </GroupTag>
                                                                <Tag size="s">
                                                                    <FormattedMessage defaultMessage="Programma" />
                                                                </Tag>
                                                            </Stack>
                                                        }
                                                        description={hit.description?.translation}
                                                        image={getImageProps(
                                                            hit.publications.favoriteImage ??
                                                                hit.publications.stills?.[0],
                                                            hit.name
                                                        )}
                                                    />
                                                </Link>
                                            </ColorSchemeProvider>
                                        )}
                                    </>
                                </Fragment>
                            ))}
                        </Stack>
                    )}
                    {!hasHits && !isFetching && (
                        <Note mt={[6, null, null, 13]}>
                            <FormattedMessage
                                id="no-results-for"
                                defaultMessage='No results for "{searchTerm}"'
                                values={{
                                    searchTerm: q,
                                }}
                            />
                        </Note>
                    )}
                    {!hasHits && isFetching && (
                        <Stack spacing={[6, null, 8]} mb={6}>
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                        </Stack>
                    )}
                </Box>
                <Box
                    gridColumnStart={[1, null, hasHitsOrIsFetching(8, 1), hasHitsOrIsFetching(9, 1)]}
                    gridColumnEnd={[6, -1]}
                >
                    <Stack
                        w="fit-content"
                        display={['flex', hasHitsOrIsFetching('flex', 'grid') as string]}
                        gap={[6, 5]}
                        gridTemplateColumns={hasHits || isFetching ? '' : `repeat(${tiles.length}, 1fr)`}
                        direction={['column', null, hasHitsOrIsFetching('column', 'row') as 'column' | 'row']}
                    >
                        {tiles}
                    </Stack>
                </Box>
            </Grid>

            {totalHits > ITEMS_PER_PAGE && (
                <Flex justifyContent="center" mt={[6, null, 9]}>
                    <Pagination
                        currentPage={page - 1}
                        setCurrentPage={(p: number) => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                            setQuery({ page: p + 1 });
                        }}
                        totalPages={Math.ceil(totalHits / ITEMS_PER_PAGE)}
                    />
                </Flex>
            )}
        </>
    );
};

const GroupTag = styled(Tag)`
    background: ${({ theme }) => theme.tokens.SyntaxBackgroundPrimaryLightest};
    color: ${({ theme }) => theme.tokens.ButtonPrimaryEnabledForeground};

    &::before {
        border-color: ${({ theme }) => theme.tokens.SyntaxBackgroundPrimaryLightest};
    }
`;

const StyledFilterCheckbox = styled(FilterCheckbox)`
    font-weight: 600;
`;

export default SearchTemplate;

/* eslint-disable */
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useApi } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum AtoZFilterEnum {
    AToZType = 'A_TO_Z_TYPE',
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    IdfaAwards = 'IDFA_AWARDS',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    Pathway = 'PATHWAY',
    Premiere = 'PREMIERE',
    Section = 'SECTION',
    Tags = 'TAGS',
}

export type AtoZFilterInput = {
    key?: InputMaybe<AtoZFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum FestivalFilmsFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    IdfaAwards = 'IDFA_AWARDS',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    Pathway = 'PATHWAY',
    Premiere = 'PREMIERE',
    Section = 'SECTION',
    Tags = 'TAGS',
}

export type FestivalFilmsFilterInput = {
    key?: InputMaybe<FestivalFilmsFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum FestivalFilterEnum {
    Compositions = 'COMPOSITIONS',
    Films = 'FILMS',
    Information = 'INFORMATION',
    News = 'NEWS',
    Sections = 'SECTIONS',
}

export enum GuestbookFilterEnum {
    Attendance = 'ATTENDANCE',
    Country = 'COUNTRY',
    GoingTo = 'GOING_TO',
    Profession = 'PROFESSION',
    Sector = 'SECTOR',
}

export type GuestbookFilterInput = {
    key?: InputMaybe<GuestbookFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum GuestbookOrderEnum {
    CompanyNameAsc = 'COMPANY_NAME_ASC',
    FullNameAsc = 'FULL_NAME_ASC',
}

export enum ImageFormatEnum {
    Jpeg = 'JPEG',
    Webp = 'WEBP',
}

export enum ScheduleFilterEnum {
    Accessibility = 'ACCESSIBILITY',
    AudienceType = 'AUDIENCE_TYPE',
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    Day = 'DAY',
    IsTalk = 'IS_TALK',
    IsVideoOnDemand = 'IS_VIDEO_ON_DEMAND',
    Section = 'SECTION',
    ShowType = 'SHOW_TYPE',
    Tags = 'TAGS',
    Venue = 'VENUE',
}

export type ScheduleFilterInput = {
    key?: InputMaybe<ScheduleFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum TicketAvailabilityStatusEnum {
    HighAvailability = 'HIGH_AVAILABILITY',
    LowAvailability = 'LOW_AVAILABILITY',
    SoldOut = 'SOLD_OUT',
}

export type FestivalAddFilmToFavoritesInput = {
    filmId: Scalars['ID'];
};

export type FestivalAddShowToCalendarInput = {
    showId: Scalars['ID'];
};

export type FestivalRemoveFilmFromFavoritesInput = {
    filmId: Scalars['ID'];
};

export type FestivalRemoveShowFromCalendarInput = {
    showId: Scalars['ID'];
};

export type AddFilmToFavoritesMutationVariables = Exact<{
    input: FestivalAddFilmToFavoritesInput;
}>;

export type AddFilmToFavoritesMutation = {
    __typename?: 'FestivalMutation';
    addFilmToFavorites: {
        __typename?: 'festivalAddFilmToFavoritesPayload';
        user?: {
            __typename?: 'MyIdfaUser';
            favoriteFilms?: Array<{
                __typename: 'Film';
                sortedTitle?: string | null;
                lengthInMinutes?: number | null;
                id: string;
                fullPreferredTitle?: string | null;
                logline?: string | null;
                credits?: Array<{
                    __typename?: 'Credit';
                    fullName?: string | null;
                    role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                }> | null;
                sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
                docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                edition?: {
                    __typename?: 'Edition';
                    id: string;
                    name: string;
                    year: number;
                    editionType: { __typename?: 'EditionType'; id: string; description: string };
                } | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            }> | null;
        } | null;
    };
};

export type AddShowToCalendarMutationVariables = Exact<{
    input: FestivalAddShowToCalendarInput;
}>;

export type AddShowToCalendarMutation = {
    __typename?: 'FestivalMutation';
    addShowToCalendar: {
        __typename?: 'festivalAddShowToCalendarPayload';
        user?: {
            __typename?: 'MyIdfaUser';
            calendar: {
                __typename?: 'Calendar';
                items: Array<
                    | { __typename?: 'Appointment' }
                    | { __typename?: 'Show' }
                    | { __typename?: 'Ticket' }
                    | { __typename?: 'VolunteerShift' }
                >;
            };
        } | null;
    };
};

export type AlphabetSearchFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<FestivalFilmsFilterInput> | FestivalFilmsFilterInput>;
}>;

export type AlphabetSearchFiltersQuery = {
    __typename?: 'FestivalQuery';
    searchFestivalFilms: {
        __typename?: 'SearchFestivalFilmsResult';
        filters: Array<{
            __typename?: 'FestivalFilmsFilter';
            filter: FestivalFilmsFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type AlphabetSearchHitsQueryVariables = Exact<{
    query?: InputMaybe<Scalars['String']>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
    filters?: InputMaybe<Array<FestivalFilmsFilterInput> | FestivalFilmsFilterInput>;
}>;

export type AlphabetSearchHitsQuery = {
    __typename?: 'FestivalQuery';
    searchFestivalFilms: {
        __typename?: 'SearchFestivalFilmsResult';
        totalHits: number;
        hits: Array<{
            __typename: 'Film';
            sortedTitle?: string | null;
            lengthInMinutes?: number | null;
            yearOfProduction?: number | null;
            id: string;
            fullPreferredTitle?: string | null;
            logline?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        }>;
    };
};

export type CompositionDetailQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompositionDetailQuery = {
    __typename?: 'FestivalQuery';
    currentFestivalComposition?: {
        __typename?: 'Composition';
        id: string;
        fullTitle?: string | null;
        special?: boolean | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
        description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        shows?: Array<{
            __typename?: 'Show';
            id: string;
            fullTitle?: string | null;
            location?: string | null;
            startOn?: string | null;
            endOn?: string | null;
            ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
            externalSaleLink?: string | null;
            isOngoingProgram?: boolean | null;
            isVideoOnDemand?: boolean | null;
            noSale?: boolean | null;
            introPaper?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            audience?: { __typename?: 'LookupValue'; key?: string | null } | null;
            accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
        }> | null;
        films?: Array<{
            __typename: 'Film';
            id: string;
            fullPreferredTitle?: string | null;
            sortedTitle?: string | null;
            logline?: string | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        }> | null;
        sections?: Array<{
            __typename?: 'EditionSection';
            id: string;
            name?: string | null;
            description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            items?: Array<
                | {
                      __typename: 'Composition';
                      id: string;
                      fullTitle?: string | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  }
                | {
                      __typename: 'Film';
                      id: string;
                      fullPreferredTitle?: string | null;
                      lengthInMinutes?: number | null;
                      isDocLab?: boolean | null;
                      yearOfProduction?: number | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                      credits?: Array<{
                          __typename?: 'Credit';
                          fullName?: string | null;
                          email?: string | null;
                          role?: {
                              __typename?: 'LookupValue';
                              key?: string | null;
                              translation?: string | null;
                          } | null;
                      }> | null;
                      countriesOfProduction?: Array<{
                          __typename?: 'LookupValue';
                          key?: string | null;
                          translation?: string | null;
                      }> | null;
                  }
            > | null;
        }> | null;
    } | null;
};

export type CompositionRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompositionRedirectQuery = {
    __typename?: 'FestivalQuery';
    currentFestivalComposition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
};

export type FavoriteFilmsQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteFilmsQuery = {
    __typename?: 'FestivalQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        favoriteFilms?: Array<{
            __typename: 'Film';
            sortedTitle?: string | null;
            lengthInMinutes?: number | null;
            id: string;
            fullPreferredTitle?: string | null;
            logline?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        }> | null;
    } | null;
};

export type FestivalFilmDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language: Scalars['String'];
}>;

export type FestivalFilmDetailQuery = {
    __typename?: 'FestivalQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        isAlternativeContent?: boolean | null;
        isDocLab?: boolean | null;
        logline?: string | null;
        yearOfProduction?: number | null;
        lengthInMinutes?: number | null;
        premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        sections?: Array<{
            __typename?: 'EditionSection';
            id: string;
            name?: string | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            items?: Array<
                | {
                      __typename: 'Composition';
                      id: string;
                      fullTitle?: string | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  }
                | {
                      __typename: 'Film';
                      isDocLab?: boolean | null;
                      yearOfProduction?: number | null;
                      lengthInMinutes?: number | null;
                      id: string;
                      fullPreferredTitle?: string | null;
                      sortedTitle?: string | null;
                      logline?: string | null;
                      credits?: Array<{
                          __typename?: 'Credit';
                          fullName?: string | null;
                          role?: {
                              __typename?: 'LookupValue';
                              translation?: string | null;
                              key?: string | null;
                          } | null;
                      }> | null;
                      docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      edition?: {
                          __typename?: 'Edition';
                          id: string;
                          name: string;
                          year: number;
                          editionType: { __typename?: 'EditionType'; id: string; description: string };
                      } | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  }
            > | null;
        }> | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        } | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            person?: {
                __typename?: 'Person';
                id: string;
                fullName?: string | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
                mainAccreditation?: {
                    __typename?: 'Accreditation';
                    publications?: {
                        __typename?: 'PublicationCollection';
                        favoriteImage?: {
                            __typename?: 'Image';
                            blurHash?: string | null;
                            webp?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                            jpeg?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                        } | null;
                    } | null;
                } | null;
            } | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        colour?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        format?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        spokenLanguages?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        shows?: Array<{
            __typename?: 'Show';
            fullTitle?: string | null;
            startOn?: string | null;
            endOn?: string | null;
            isOngoingProgram?: boolean | null;
            isVideoOnDemand?: boolean | null;
            location?: string | null;
            ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
            externalSaleLink?: string | null;
            id: string;
            noSale?: boolean | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
            introPaper?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
        }> | null;
    } | null;
};

export type FestivalSectionDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language: Scalars['String'];
}>;

export type FestivalSectionDetailQuery = {
    __typename?: 'FestivalQuery';
    section?: {
        __typename?: 'EditionSection';
        id: string;
        name?: string | null;
        description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        items?: Array<
            | {
                  __typename: 'Composition';
                  id: string;
                  fullTitle?: string | null;
                  sortedTitle?: string | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  isDocLab?: boolean | null;
                  id: string;
                  fullPreferredTitle?: string | null;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  yearOfProduction?: number | null;
                  lengthInMinutes?: number | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
                  credits?: Array<{
                      __typename?: 'Credit';
                      fullName?: string | null;
                      role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  }> | null;
              }
        > | null;
    } | null;
};

export type FestivalSectionsQueryVariables = Exact<{ [key: string]: never }>;

export type FestivalSectionsQuery = {
    __typename?: 'FestivalQuery';
    currentFestivalSections?: Array<{
        __typename?: 'EditionSection';
        id: string;
        name?: string | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
    }> | null;
};

export type CalendarAppointmentFragment = {
    __typename?: 'Appointment';
    endsOn?: string | null;
    isOnline?: boolean | null;
    location?: string | null;
    startsOn?: string | null;
    subject?: string | null;
    appointmentType?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
};

export type CalendarShowFragment = {
    __typename?: 'Show';
    id: string;
    fullTitle?: string | null;
    startOn?: string | null;
    endOn?: string | null;
    location?: string | null;
    ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
    externalSaleLink?: string | null;
    noSale?: boolean | null;
    validFrom?: string | null;
    validUntil?: string | null;
    isVideoOnDemand?: boolean | null;
    audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    film?: {
        __typename: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        sortedTitle?: string | null;
        logline?: string | null;
        docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
    composition?: {
        __typename: 'Composition';
        id: string;
        fullTitle?: string | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
    } | null;
};

export type CompositionHitFragment = {
    __typename: 'Composition';
    id: string;
    fullTitle?: string | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
    } | null;
};

export type EditionSectionHitFragment = {
    __typename: 'EditionSection';
    id: string;
    name?: string | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
    } | null;
};

export type FavoriteFilmFragment = {
    __typename: 'Film';
    sortedTitle?: string | null;
    lengthInMinutes?: number | null;
    id: string;
    fullPreferredTitle?: string | null;
    logline?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type FutureTicketsQueryVariables = Exact<{ [key: string]: never }>;

export type FutureTicketsQuery = {
    __typename?: 'FestivalQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        futureShowTickets?: Array<{
            __typename?: 'Ticket';
            name?: string | null;
            qrCode?: string | null;
            show: {
                __typename?: 'Show';
                fullTitle?: string | null;
                startOn?: string | null;
                endOn?: string | null;
                location?: string | null;
            };
        }> | null;
    } | null;
};

export type GuestlistExportUrlQueryVariables = Exact<{
    filters?: InputMaybe<Array<GuestbookFilterInput> | GuestbookFilterInput>;
}>;

export type GuestlistExportUrlQuery = {
    __typename?: 'FestivalQuery';
    searchGuestbook: { __typename?: 'SearchGuestbookResult'; exportUrl?: string | null };
};

export type MyVolunteerFormsQueryVariables = Exact<{ [key: string]: never }>;

export type MyVolunteerFormsQuery = {
    __typename?: 'FestivalQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        email: string;
        volunteerForms: {
            __typename?: 'FionaFormCollection';
            availableForms?: Array<{ __typename?: 'FionaForm'; title: string; url: string }> | null;
            myEntries?: Array<{
                __typename?: 'FionaFormEntry';
                isSubmitted: boolean;
                form: { __typename?: 'FionaForm'; title: string; url: string };
            }> | null;
        };
    } | null;
};

export type RemoveFilmFromFavoritesMutationVariables = Exact<{
    input: FestivalRemoveFilmFromFavoritesInput;
}>;

export type RemoveFilmFromFavoritesMutation = {
    __typename?: 'FestivalMutation';
    removeFilmFromFavorites: {
        __typename?: 'festivalRemoveFilmFromFavoritesPayload';
        user?: {
            __typename?: 'MyIdfaUser';
            favoriteFilms?: Array<{
                __typename: 'Film';
                sortedTitle?: string | null;
                lengthInMinutes?: number | null;
                id: string;
                fullPreferredTitle?: string | null;
                logline?: string | null;
                credits?: Array<{
                    __typename?: 'Credit';
                    fullName?: string | null;
                    role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                }> | null;
                sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
                docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                edition?: {
                    __typename?: 'Edition';
                    id: string;
                    name: string;
                    year: number;
                    editionType: { __typename?: 'EditionType'; id: string; description: string };
                } | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            }> | null;
        } | null;
    };
};

export type RemoveShowFromCalendarMutationVariables = Exact<{
    input: FestivalRemoveShowFromCalendarInput;
}>;

export type RemoveShowFromCalendarMutation = {
    __typename?: 'FestivalMutation';
    removeShowFromCalendar: {
        __typename?: 'festivalRemoveShowFromCalendarPayload';
        user?: {
            __typename?: 'MyIdfaUser';
            calendar: {
                __typename?: 'Calendar';
                items: Array<
                    | { __typename?: 'Appointment' }
                    | { __typename?: 'Show' }
                    | { __typename?: 'Ticket' }
                    | { __typename?: 'VolunteerShift' }
                >;
            };
        } | null;
    };
};

export type SearchAtoZFiltersQueryVariables = Exact<{
    filters: Array<AtoZFilterInput> | AtoZFilterInput;
}>;

export type SearchAtoZFiltersQuery = {
    __typename?: 'FestivalQuery';
    searchAtoZ: {
        __typename?: 'SearchAtoZResult';
        filters: Array<{
            __typename?: 'AtoZFilter';
            filter: AtoZFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; key: string; amount: number }>;
        }>;
    };
};

export type SearchAtoZHitsQueryVariables = Exact<{
    query?: InputMaybe<Scalars['String']>;
    filters: Array<AtoZFilterInput> | AtoZFilterInput;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchAtoZHitsQuery = {
    __typename?: 'FestivalQuery';
    searchAtoZ: {
        __typename?: 'SearchAtoZResult';
        totalHits: number;
        hits: Array<
            | {
                  __typename: 'Composition';
                  aToZType?: Array<string | null> | null;
                  id: string;
                  fullTitle?: string | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                      stills?: Array<{
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      }> | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  aToZType?: Array<string | null> | null;
                  sortedTitle?: string | null;
                  lengthInMinutes?: number | null;
                  yearOfProduction?: number | null;
                  id: string;
                  fullPreferredTitle?: string | null;
                  logline?: string | null;
                  credits?: Array<{
                      __typename?: 'Credit';
                      fullName?: string | null;
                      role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  }> | null;
                  sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Show';
                  aToZType?: Array<string | null> | null;
                  id: string;
                  fullTitle?: string | null;
                  startOn?: string | null;
                  endOn?: string | null;
                  location?: string | null;
                  ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
                  externalSaleLink?: string | null;
                  noSale?: boolean | null;
                  validFrom?: string | null;
                  validUntil?: string | null;
                  isVideoOnDemand?: boolean | null;
                  audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                  film?: {
                      __typename: 'Film';
                      id: string;
                      fullPreferredTitle?: string | null;
                      sortedTitle?: string | null;
                      logline?: string | null;
                      docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      edition?: {
                          __typename?: 'Edition';
                          id: string;
                          name: string;
                          year: number;
                          editionType: { __typename?: 'EditionType'; id: string; description: string };
                      } | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  } | null;
                  composition?: {
                      __typename: 'Composition';
                      id: string;
                      fullTitle?: string | null;
                      description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                          stills?: Array<{
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          }> | null;
                      } | null;
                  } | null;
              }
        >;
    };
};

export type SearchGuestlistFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type SearchGuestlistFiltersQuery = {
    __typename?: 'FestivalQuery';
    searchGuestbook: {
        __typename?: 'SearchGuestbookResult';
        filters: Array<{
            __typename?: 'GuestbookFilter';
            filter: GuestbookFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchGuestlistHitsQueryVariables = Exact<{
    filters?: InputMaybe<Array<GuestbookFilterInput> | GuestbookFilterInput>;
    orderBy: GuestbookOrderEnum;
    query?: InputMaybe<Scalars['String']>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchGuestlistHitsQuery = {
    __typename?: 'FestivalQuery';
    searchGuestbook: {
        __typename?: 'SearchGuestbookResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Accreditation';
            id: string;
            fullName?: string | null;
            sortedFullName?: string | null;
            companyName?: string | null;
            email?: string | null;
            companyProfile?: string | null;
            accomodationStartsOn?: string | null;
            accomodationEndsOn?: string | null;
            attendanceType?: string | null;
            socials?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
            website?: { __typename?: 'Link'; title: string; value: string } | null;
            profession?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            films?: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null } | null> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        }>;
    };
};

export type SearchPressIndustryQueryVariables = Exact<{
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchPressIndustryQuery = {
    __typename?: 'FestivalQuery';
    searchOfcFilms: {
        __typename?: 'SearchOfcFilmsResult';
        totalHits: number;
        hits: Array<{
            __typename: 'Film';
            ofcStartOn?: string | null;
            ofcEndOn?: string | null;
            id: string;
            fullPreferredTitle?: string | null;
            sortedTitle?: string | null;
            logline?: string | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        }>;
    };
};

export type SearchScheduleFiltersQueryVariables = Exact<{
    filters: Array<ScheduleFilterInput> | ScheduleFilterInput;
}>;

export type SearchScheduleFiltersQuery = {
    __typename?: 'FestivalQuery';
    searchSchedule: {
        __typename?: 'SearchScheduleResult';
        filters: Array<{
            __typename?: 'ScheduleFilter';
            filter: ScheduleFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; key: string; amount: number }>;
        }>;
    };
};

export type SearchScheduleHitsQueryVariables = Exact<{
    filters: Array<ScheduleFilterInput> | ScheduleFilterInput;
}>;

export type SearchScheduleHitsQuery = {
    __typename?: 'FestivalQuery';
    searchSchedule: {
        __typename?: 'SearchScheduleResult';
        totalHits: number;
        hits: Array<{
            __typename: 'Show';
            id: string;
            fullTitle?: string | null;
            ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
            externalSaleLink?: string | null;
            noSale?: boolean | null;
            validFrom?: string | null;
            validUntil?: string | null;
            isOngoingProgram?: boolean | null;
            isVideoOnDemand?: boolean | null;
            startOn?: string | null;
            endOn?: string | null;
            location?: string | null;
            intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            film?: {
                __typename?: 'Film';
                id: string;
                fullPreferredTitle?: string | null;
                intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
            audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            composition?: {
                __typename?: 'Composition';
                id: string;
                fullTitle?: string | null;
                intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                    stills?: Array<{
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    }> | null;
                } | null;
            } | null;
        }>;
    };
};

export type TicketStatusQueryVariables = Exact<{
    fionaId: Scalars['ID'];
}>;

export type TicketStatusQuery = {
    __typename?: 'FestivalQuery';
    ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
};

export type UserCalendarQueryVariables = Exact<{ [key: string]: never }>;

export type UserCalendarQuery = {
    __typename?: 'FestivalQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        calendar: {
            __typename?: 'Calendar';
            iCalUrl?: string | null;
            items: Array<
                | {
                      __typename: 'Appointment';
                      endsOn?: string | null;
                      isOnline?: boolean | null;
                      location?: string | null;
                      startsOn?: string | null;
                      subject?: string | null;
                      appointmentType?: {
                          __typename?: 'LookupValue';
                          key?: string | null;
                          translation?: string | null;
                      } | null;
                  }
                | {
                      __typename: 'Show';
                      id: string;
                      fullTitle?: string | null;
                      startOn?: string | null;
                      endOn?: string | null;
                      location?: string | null;
                      ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
                      externalSaleLink?: string | null;
                      noSale?: boolean | null;
                      validFrom?: string | null;
                      validUntil?: string | null;
                      isVideoOnDemand?: boolean | null;
                      audience?: {
                          __typename?: 'LookupValue';
                          key?: string | null;
                          translation?: string | null;
                      } | null;
                      intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                      film?: {
                          __typename: 'Film';
                          id: string;
                          fullPreferredTitle?: string | null;
                          sortedTitle?: string | null;
                          logline?: string | null;
                          docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                          description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                          intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                          edition?: {
                              __typename?: 'Edition';
                              id: string;
                              name: string;
                              year: number;
                              editionType: { __typename?: 'EditionType'; id: string; description: string };
                          } | null;
                          publications?: {
                              __typename?: 'PublicationCollection';
                              favoriteImage?: {
                                  __typename?: 'Image';
                                  blurHash?: string | null;
                                  webp?: Array<{
                                      __typename?: 'ImageTransform';
                                      url: string;
                                      width: number;
                                      height: number;
                                  }> | null;
                                  jpeg?: Array<{
                                      __typename?: 'ImageTransform';
                                      url: string;
                                      width: number;
                                      height: number;
                                  }> | null;
                              } | null;
                          } | null;
                      } | null;
                      composition?: {
                          __typename: 'Composition';
                          id: string;
                          fullTitle?: string | null;
                          description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                          intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                          publications?: {
                              __typename?: 'PublicationCollection';
                              favoriteImage?: {
                                  __typename?: 'Image';
                                  blurHash?: string | null;
                                  webp?: Array<{
                                      __typename?: 'ImageTransform';
                                      url: string;
                                      width: number;
                                      height: number;
                                  }> | null;
                                  jpeg?: Array<{
                                      __typename?: 'ImageTransform';
                                      url: string;
                                      width: number;
                                      height: number;
                                  }> | null;
                              } | null;
                              stills?: Array<{
                                  __typename?: 'Image';
                                  blurHash?: string | null;
                                  webp?: Array<{
                                      __typename?: 'ImageTransform';
                                      url: string;
                                      width: number;
                                      height: number;
                                  }> | null;
                                  jpeg?: Array<{
                                      __typename?: 'ImageTransform';
                                      url: string;
                                      width: number;
                                      height: number;
                                  }> | null;
                              }> | null;
                          } | null;
                      } | null;
                  }
                | {
                      __typename: 'Ticket';
                      name?: string | null;
                      show: {
                          __typename?: 'Show';
                          id: string;
                          fullTitle?: string | null;
                          startOn?: string | null;
                          endOn?: string | null;
                          location?: string | null;
                          ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
                          externalSaleLink?: string | null;
                          noSale?: boolean | null;
                          validFrom?: string | null;
                          validUntil?: string | null;
                          isVideoOnDemand?: boolean | null;
                          audience?: {
                              __typename?: 'LookupValue';
                              key?: string | null;
                              translation?: string | null;
                          } | null;
                          intro?: {
                              __typename?: 'LookupValue';
                              key?: string | null;
                              translation?: string | null;
                          } | null;
                          film?: {
                              __typename: 'Film';
                              id: string;
                              fullPreferredTitle?: string | null;
                              sortedTitle?: string | null;
                              logline?: string | null;
                              docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                              description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                              intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                              edition?: {
                                  __typename?: 'Edition';
                                  id: string;
                                  name: string;
                                  year: number;
                                  editionType: { __typename?: 'EditionType'; id: string; description: string };
                              } | null;
                              publications?: {
                                  __typename?: 'PublicationCollection';
                                  favoriteImage?: {
                                      __typename?: 'Image';
                                      blurHash?: string | null;
                                      webp?: Array<{
                                          __typename?: 'ImageTransform';
                                          url: string;
                                          width: number;
                                          height: number;
                                      }> | null;
                                      jpeg?: Array<{
                                          __typename?: 'ImageTransform';
                                          url: string;
                                          width: number;
                                          height: number;
                                      }> | null;
                                  } | null;
                              } | null;
                          } | null;
                          composition?: {
                              __typename: 'Composition';
                              id: string;
                              fullTitle?: string | null;
                              description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                              intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                              publications?: {
                                  __typename?: 'PublicationCollection';
                                  favoriteImage?: {
                                      __typename?: 'Image';
                                      blurHash?: string | null;
                                      webp?: Array<{
                                          __typename?: 'ImageTransform';
                                          url: string;
                                          width: number;
                                          height: number;
                                      }> | null;
                                      jpeg?: Array<{
                                          __typename?: 'ImageTransform';
                                          url: string;
                                          width: number;
                                          height: number;
                                      }> | null;
                                  } | null;
                                  stills?: Array<{
                                      __typename?: 'Image';
                                      blurHash?: string | null;
                                      webp?: Array<{
                                          __typename?: 'ImageTransform';
                                          url: string;
                                          width: number;
                                          height: number;
                                      }> | null;
                                      jpeg?: Array<{
                                          __typename?: 'ImageTransform';
                                          url: string;
                                          width: number;
                                          height: number;
                                      }> | null;
                                  }> | null;
                              } | null;
                          } | null;
                      };
                  }
                | {
                      __typename: 'VolunteerShift';
                      startsOn?: string | null;
                      endsOn?: string | null;
                      location?: string | null;
                      isOnline?: boolean | null;
                      shiftType?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      positionType?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  }
            >;
        };
    } | null;
};

type UserCalendarCalendarItem_Appointment_Fragment = {
    __typename: 'Appointment';
    endsOn?: string | null;
    isOnline?: boolean | null;
    location?: string | null;
    startsOn?: string | null;
    subject?: string | null;
    appointmentType?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
};

type UserCalendarCalendarItem_Show_Fragment = {
    __typename: 'Show';
    id: string;
    fullTitle?: string | null;
    startOn?: string | null;
    endOn?: string | null;
    location?: string | null;
    ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
    externalSaleLink?: string | null;
    noSale?: boolean | null;
    validFrom?: string | null;
    validUntil?: string | null;
    isVideoOnDemand?: boolean | null;
    audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    film?: {
        __typename: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        sortedTitle?: string | null;
        logline?: string | null;
        docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
    composition?: {
        __typename: 'Composition';
        id: string;
        fullTitle?: string | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
    } | null;
};

type UserCalendarCalendarItem_Ticket_Fragment = {
    __typename: 'Ticket';
    name?: string | null;
    show: {
        __typename?: 'Show';
        id: string;
        fullTitle?: string | null;
        startOn?: string | null;
        endOn?: string | null;
        location?: string | null;
        ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
        externalSaleLink?: string | null;
        noSale?: boolean | null;
        validFrom?: string | null;
        validUntil?: string | null;
        isVideoOnDemand?: boolean | null;
        audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        film?: {
            __typename: 'Film';
            id: string;
            fullPreferredTitle?: string | null;
            sortedTitle?: string | null;
            logline?: string | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        } | null;
        composition?: {
            __typename: 'Composition';
            id: string;
            fullTitle?: string | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
            } | null;
        } | null;
    };
};

type UserCalendarCalendarItem_VolunteerShift_Fragment = {
    __typename: 'VolunteerShift';
    startsOn?: string | null;
    endsOn?: string | null;
    location?: string | null;
    isOnline?: boolean | null;
    shiftType?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    positionType?: { __typename?: 'LookupValue'; translation?: string | null } | null;
};

export type UserCalendarCalendarItemFragment =
    | UserCalendarCalendarItem_Appointment_Fragment
    | UserCalendarCalendarItem_Show_Fragment
    | UserCalendarCalendarItem_Ticket_Fragment
    | UserCalendarCalendarItem_VolunteerShift_Fragment;

export type ArchiveFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type BerthaFundFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    fundingAmount?: string | null;
    lengthInMinutes?: number | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultCreditFragment = {
    __typename?: 'Credit';
    fullName?: string | null;
    email?: string | null;
    role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
};

export type DocLabFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    lengthInMinutes?: number | null;
    yearOfProduction?: number | null;
    description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        experiences?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        docLabVideo?: { __typename?: 'Video'; id: string; title?: string | null; value: string } | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocsForSaleFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    docsForSaleSeries?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    docsForSaleSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    viewingReport?: { __typename?: 'ViewingReport'; pdf: string; csv: string } | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocschoolFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    educationLevel?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        teacherMaterial?: Array<{ __typename?: 'Document'; title?: string | null; url: string }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    kijkwijzer?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultEditionFragment = {
    __typename?: 'Edition';
    id: string;
    name: string;
    year: number;
    editionType: { __typename?: 'EditionType'; id: string; description: string };
};

export type FestivalFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    isDocLab?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{
        __typename?: 'EditionSection';
        id: string;
        name?: string | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        items?: Array<
            | {
                  __typename: 'Composition';
                  id: string;
                  fullTitle?: string | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  isDocLab?: boolean | null;
                  yearOfProduction?: number | null;
                  lengthInMinutes?: number | null;
                  id: string;
                  fullPreferredTitle?: string | null;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  credits?: Array<{
                      __typename?: 'Credit';
                      fullName?: string | null;
                      role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  }> | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
        > | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    colour?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    format?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    shows?: Array<{
        __typename?: 'Show';
        fullTitle?: string | null;
        startOn?: string | null;
        endOn?: string | null;
        isOngoingProgram?: boolean | null;
        isVideoOnDemand?: boolean | null;
        location?: string | null;
        ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
        externalSaleLink?: string | null;
        id: string;
        noSale?: boolean | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
        introPaper?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    }> | null;
};

export type FilmCardFilmFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    fullPreferredTitle?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type FilmHitFragment = {
    __typename: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    sortedTitle?: string | null;
    logline?: string | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type ForumFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    logline?: string | null;
    startProduction?: string | null;
    monthOfProduction?: number | null;
    yearOfProduction?: number | null;
    startRelease?: string | null;
    lengths?: string | null;
    totalBudget?: number | null;
    financingInPlace?: number | null;
    requestedFinancing?: number | null;
    interestedInFollowingProducers?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        forumPerson?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    forumSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumProjectDescriptionEntry?: {
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    } | null;
    standAloneVersions?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    typeOfNewMediaProject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    financiers?: Array<{ __typename?: 'Financier'; contribution: number; id: string; name: string }> | null;
    forumOpenTalent?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumCoprodPotential?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumBiography?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    contactPerson?: { __typename?: 'ContactPerson'; email?: string | null; fullName?: string | null } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultApiImageFragment = {
    __typename?: 'Image';
    blurHash?: string | null;
    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
};

export type PageHitFragment = {
    __typename: 'Page';
    id: string;
    title: string;
    pageType: string;
    introText?: string | null;
    url: string;
    breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    thumbnail?: {
        __typename?: 'Image';
        blurHash?: string | null;
        webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    } | null;
};

export type ProjectSpaceFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    projectSpaceProductionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
};

export type UserProfileFragment = {
    __typename?: 'MyIdfaUser';
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    roles?: Array<string> | null;
    person?: {
        __typename?: 'Person';
        mainAccreditation?: {
            __typename?: 'Accreditation';
            badges?: Array<{ __typename?: 'Badge'; id: string; guestbookBadge?: string | null } | null> | null;
        } | null;
    } | null;
    favoriteFilms?: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }> | null;
    calendar: {
        __typename: 'Calendar';
        iCalUrl?: string | null;
        items: Array<
            | { __typename?: 'Appointment' }
            | { __typename: 'Show'; id: string }
            | { __typename?: 'Ticket' }
            | { __typename?: 'VolunteerShift' }
        >;
    };
};

type UserProfileCalendarItem_Appointment_Fragment = { __typename?: 'Appointment' };

type UserProfileCalendarItem_Show_Fragment = { __typename: 'Show'; id: string };

type UserProfileCalendarItem_Ticket_Fragment = { __typename?: 'Ticket' };

type UserProfileCalendarItem_VolunteerShift_Fragment = { __typename?: 'VolunteerShift' };

export type UserProfileCalendarItemFragment =
    | UserProfileCalendarItem_Appointment_Fragment
    | UserProfileCalendarItem_Show_Fragment
    | UserProfileCalendarItem_Ticket_Fragment
    | UserProfileCalendarItem_VolunteerShift_Fragment;

export const DefaultApiImageFragmentDoc = `
    fragment DefaultApiImage on Image {
  webp: transforms(format: WEBP) {
    url
    width
    height
  }
  jpeg: transforms(format: JPEG) {
    url
    width
    height
  }
  blurHash
}
    `;
export const EditionSectionHitFragmentDoc = `
    fragment EditionSectionHit on EditionSection {
  __typename
  id
  name
  description {
    translation
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
  }
}
    `;
export const DefaultEditionFragmentDoc = `
    fragment DefaultEdition on Edition {
  id
  name
  year
  editionType {
    id
    description
  }
}
    `;
export const FilmHitFragmentDoc = `
    fragment FilmHit on Film {
  __typename
  id
  fullPreferredTitle
  sortedTitle
  docschoolOneliner {
    translation
  }
  logline
  description {
    translation
  }
  intro {
    translation
  }
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const FavoriteFilmFragmentDoc = `
    fragment FavoriteFilm on Film {
  ...FilmHit
  sortedTitle
  credits {
    fullName
    role {
      translation
      key
    }
  }
  lengthInMinutes
  sections {
    id
    name
  }
}
    `;
export const CompositionHitFragmentDoc = `
    fragment CompositionHit on Composition {
  __typename
  id
  fullTitle
  description {
    translation
  }
  intro {
    translation
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
  }
}
    `;
export const CalendarShowFragmentDoc = `
    fragment CalendarShow on Show {
  id
  fullTitle
  startOn
  endOn
  location
  ticketAvailabilityStatus
  externalSaleLink
  noSale
  audience {
    key
    translation
  }
  validFrom
  validUntil
  intro {
    key
    translation
  }
  isVideoOnDemand
  film {
    ...FilmHit
  }
  composition {
    ...CompositionHit
  }
}
    `;
export const CalendarAppointmentFragmentDoc = `
    fragment CalendarAppointment on Appointment {
  appointmentType {
    key
    translation
  }
  endsOn
  isOnline
  location
  startsOn
  subject
}
    `;
export const UserCalendarCalendarItemFragmentDoc = `
    fragment UserCalendarCalendarItem on CalendarItem {
  ... on Show {
    __typename
    ...CalendarShow
  }
  ... on Ticket {
    __typename
    name
    show {
      ...CalendarShow
    }
  }
  ... on Appointment {
    __typename
    ...CalendarAppointment
  }
  ... on VolunteerShift {
    __typename
    shiftType {
      translation
    }
    positionType {
      translation
    }
    startsOn
    endsOn
    location
    isOnline
  }
}
    `;
export const DefaultCreditFragmentDoc = `
    fragment DefaultCredit on Credit {
  fullName
  role {
    key
    translation
  }
  email
}
    `;
export const ArchiveFilmDetailsFragmentDoc = `
    fragment ArchiveFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const BerthaFundFilmDetailsFragmentDoc = `
    fragment BerthaFundFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    translation
  }
  fundingAmount
  requestType {
    sortOrder
    translation
  }
  lengthInMinutes
  logline
  yearOfProduction
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocLabFilmDetailsFragmentDoc = `
    fragment DocLabFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  description {
    key
    translation
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    experiences {
      id
      title
      value
    }
    docLabVideo {
      id
      title
      value
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  yearOfProduction
  sections {
    id
    name
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocsForSaleFilmDetailsFragmentDoc = `
    fragment DocsForSaleFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  lengths
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  subject {
    sortOrder
    translation
  }
  logline
  docsForSaleSeries
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  docsForSaleSynopsisEntry {
    key
    translation
  }
  viewingReport {
    pdf
    csv
  }
  tags {
    key
    translation
  }
}
    `;
export const DocschoolFilmDetailsFragmentDoc = `
    fragment DocschoolFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  educationLevel {
    translation(language: $language)
  }
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  isAlternativeContent
  docschoolOneliner {
    key
    translation(language: $language)
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
    teacherMaterial {
      title
      url
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  kijkwijzer {
    sortOrder
    translation(language: $language)
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const FestivalFilmDetailsFragmentDoc = `
    fragment FestivalFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  isDocLab
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  colour {
    key
    translation
  }
  format {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
    description {
      translation
    }
    items {
      ... on Composition {
        __typename
        id
        fullTitle
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
      ... on Film {
        __typename
        isDocLab
        ...FilmHit
        credits {
          fullName
          role {
            translation
            key
          }
        }
        yearOfProduction
        lengthInMinutes
      }
    }
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  shows {
    fullTitle
    startOn
    endOn
    isOngoingProgram
    isVideoOnDemand
    intro {
      translation
    }
    location
    ticketAvailabilityStatus
    externalSaleLink
    id
    composition {
      fullTitle
    }
    introPaper {
      translation
    }
    audience {
      key
      translation
    }
    accessibility {
      translation
    }
    noSale
  }
}
    `;
export const FilmCardFilmFragmentDoc = `
    fragment FilmCardFilm on Film {
  id
  credits {
    fullName
    role {
      key
    }
  }
  yearOfProduction
  fullPreferredTitle
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const ForumFilmDetailsFragmentDoc = `
    fragment ForumFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    links {
      title
      value
    }
    films {
      id
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  requestType {
    sortOrder
    translation
  }
  productionStatus {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
    forumPerson: person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  logline
  countriesOfCoproduction {
    sortOrder
    translation
  }
  forumSynopsisEntry {
    key
    translation
  }
  forumProjectDescriptionEntry {
    key
    translation
  }
  startProduction
  monthOfProduction
  yearOfProduction
  startRelease
  lengths
  standAloneVersions {
    sortOrder
    translation
  }
  typeOfNewMediaProject {
    sortOrder
    translation
  }
  totalBudget
  financingInPlace
  requestedFinancing
  financiers {
    contribution
    id
    name
  }
  forumOpenTalent {
    key
    translation
  }
  forumCoprodPotential {
    key
    translation
  }
  interestedInFollowingProducers
  forumBiography {
    key
    translation
  }
  contactPerson {
    email
    fullName
  }
  subject {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  tags {
    key
    translation
  }
}
    `;
export const PageHitFragmentDoc = `
    fragment PageHit on Page {
  id
  __typename
  title
  pageType
  introText
  url
  breadcrumbs {
    title
    value
  }
  thumbnail {
    ...DefaultApiImage
  }
}
    `;
export const ProjectSpaceFilmDetailsFragmentDoc = `
    fragment ProjectSpaceFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    sortOrder
    translation
  }
  lengthInMinutes
  lengths
  requestType {
    sortOrder
    translation
  }
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation
  }
  logline
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  tags {
    key
    translation
  }
  projectSpaceProductionStatus {
    sortOrder
    translation
  }
}
    `;
export const UserProfileCalendarItemFragmentDoc = `
    fragment UserProfileCalendarItem on CalendarItem {
  ... on Show {
    __typename
    id
  }
}
    `;
export const UserProfileFragmentDoc = `
    fragment UserProfile on MyIdfaUser {
  firstName
  lastName
  email
  roles
  person {
    mainAccreditation {
      badges {
        id
        guestbookBadge
      }
    }
  }
  favoriteFilms {
    id
    fullPreferredTitle
  }
  calendar {
    ... on Calendar {
      __typename
      iCalUrl
      items {
        ... on CalendarItem {
          ...UserProfileCalendarItem
        }
      }
    }
  }
}
    `;
export const AddFilmToFavoritesDocument = `
    mutation addFilmToFavorites($input: festivalAddFilmToFavoritesInput!) {
  addFilmToFavorites(input: $input) {
    user {
      favoriteFilms {
        ...FavoriteFilm
      }
    }
  }
}
    ${FavoriteFilmFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useAddFilmToFavoritesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<AddFilmToFavoritesMutation, TError, AddFilmToFavoritesMutationVariables, TContext>
) =>
    useMutation<AddFilmToFavoritesMutation, TError, AddFilmToFavoritesMutationVariables, TContext>(
        ['addFilmToFavorites'],
        useApi<AddFilmToFavoritesMutation, AddFilmToFavoritesMutationVariables>(AddFilmToFavoritesDocument),
        options
    );
export const AddShowToCalendarDocument = `
    mutation addShowToCalendar($input: festivalAddShowToCalendarInput!) {
  addShowToCalendar(input: $input) {
    user {
      calendar {
        items {
          ... on CalendarItem {
            ... on Show {
              __typename
              id
            }
          }
        }
      }
    }
  }
}
    `;
export const useAddShowToCalendarMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<AddShowToCalendarMutation, TError, AddShowToCalendarMutationVariables, TContext>
) =>
    useMutation<AddShowToCalendarMutation, TError, AddShowToCalendarMutationVariables, TContext>(
        ['addShowToCalendar'],
        useApi<AddShowToCalendarMutation, AddShowToCalendarMutationVariables>(AddShowToCalendarDocument),
        options
    );
export const AlphabetSearchFiltersDocument = `
    query alphabetSearchFilters($filters: [FestivalFilmsFilterInput!]) {
  searchFestivalFilms(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useAlphabetSearchFiltersQuery = <TData = AlphabetSearchFiltersQuery, TError = unknown>(
    variables?: AlphabetSearchFiltersQueryVariables,
    options?: UseQueryOptions<AlphabetSearchFiltersQuery, TError, TData>
) =>
    useQuery<AlphabetSearchFiltersQuery, TError, TData>(
        variables === undefined ? ['alphabetSearchFilters'] : ['alphabetSearchFilters', variables],
        useApi<AlphabetSearchFiltersQuery, AlphabetSearchFiltersQueryVariables>(AlphabetSearchFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useAlphabetSearchFiltersQuery.document = AlphabetSearchFiltersDocument;

useAlphabetSearchFiltersQuery.getKey = (variables?: AlphabetSearchFiltersQueryVariables) =>
    variables === undefined ? ['alphabetSearchFilters'] : ['alphabetSearchFilters', variables];
export const AlphabetSearchHitsDocument = `
    query alphabetSearchHits($query: String, $limit: Int!, $offset: Int!, $filters: [FestivalFilmsFilterInput!]) {
  searchFestivalFilms(
    query: $query
    limit: $limit
    offset: $offset
    filters: $filters
  ) {
    totalHits
    hits {
      sortedTitle
      ...FilmHit
      credits {
        fullName
        role {
          translation
          key
        }
      }
      lengthInMinutes
      yearOfProduction
      sections {
        id
        name
      }
    }
  }
}
    ${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useAlphabetSearchHitsQuery = <TData = AlphabetSearchHitsQuery, TError = unknown>(
    variables: AlphabetSearchHitsQueryVariables,
    options?: UseQueryOptions<AlphabetSearchHitsQuery, TError, TData>
) =>
    useQuery<AlphabetSearchHitsQuery, TError, TData>(
        ['alphabetSearchHits', variables],
        useApi<AlphabetSearchHitsQuery, AlphabetSearchHitsQueryVariables>(AlphabetSearchHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useAlphabetSearchHitsQuery.document = AlphabetSearchHitsDocument;

useAlphabetSearchHitsQuery.getKey = (variables: AlphabetSearchHitsQueryVariables) => ['alphabetSearchHits', variables];
export const CompositionDetailDocument = `
    query compositionDetail($id: ID!) {
  currentFestivalComposition(id: $id) {
    id
    fullTitle
    special
    publications {
      favoriteImage {
        ...DefaultApiImage
      }
      stills {
        ...DefaultApiImage
      }
    }
    description {
      key
      translation
    }
    shows {
      id
      fullTitle
      location
      startOn
      endOn
      ticketAvailabilityStatus
      externalSaleLink
      isOngoingProgram
      isVideoOnDemand
      noSale
      introPaper {
        key
        translation
      }
      audience {
        key
      }
      accessibility {
        translation
      }
    }
    films {
      ...FilmHit
    }
    sections {
      id
      name
      description {
        key
        translation
      }
      items {
        __typename
        ... on Composition {
          id
          fullTitle
          publications {
            favoriteImage {
              ...DefaultApiImage
            }
          }
        }
        ... on Film {
          id
          fullPreferredTitle
          publications {
            favoriteImage {
              ...DefaultApiImage
            }
          }
          lengthInMinutes
          isDocLab
          credits {
            ...DefaultCredit
          }
          yearOfProduction
          countriesOfProduction {
            key
            translation
          }
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultCreditFragmentDoc}`;
export const useCompositionDetailQuery = <TData = CompositionDetailQuery, TError = unknown>(
    variables: CompositionDetailQueryVariables,
    options?: UseQueryOptions<CompositionDetailQuery, TError, TData>
) =>
    useQuery<CompositionDetailQuery, TError, TData>(
        ['compositionDetail', variables],
        useApi<CompositionDetailQuery, CompositionDetailQueryVariables>(CompositionDetailDocument).bind(
            null,
            variables
        ),
        options
    );
useCompositionDetailQuery.document = CompositionDetailDocument;

useCompositionDetailQuery.getKey = (variables: CompositionDetailQueryVariables) => ['compositionDetail', variables];
export const CompositionRedirectDocument = `
    query compositionRedirect($id: ID!) {
  currentFestivalComposition(id: $id) {
    fullTitle
  }
}
    `;
export const useCompositionRedirectQuery = <TData = CompositionRedirectQuery, TError = unknown>(
    variables: CompositionRedirectQueryVariables,
    options?: UseQueryOptions<CompositionRedirectQuery, TError, TData>
) =>
    useQuery<CompositionRedirectQuery, TError, TData>(
        ['compositionRedirect', variables],
        useApi<CompositionRedirectQuery, CompositionRedirectQueryVariables>(CompositionRedirectDocument).bind(
            null,
            variables
        ),
        options
    );
useCompositionRedirectQuery.document = CompositionRedirectDocument;

useCompositionRedirectQuery.getKey = (variables: CompositionRedirectQueryVariables) => [
    'compositionRedirect',
    variables,
];
export const FavoriteFilmsDocument = `
    query favoriteFilms {
  viewer {
    favoriteFilms {
      ...FavoriteFilm
    }
  }
}
    ${FavoriteFilmFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useFavoriteFilmsQuery = <TData = FavoriteFilmsQuery, TError = unknown>(
    variables?: FavoriteFilmsQueryVariables,
    options?: UseQueryOptions<FavoriteFilmsQuery, TError, TData>
) =>
    useQuery<FavoriteFilmsQuery, TError, TData>(
        variables === undefined ? ['favoriteFilms'] : ['favoriteFilms', variables],
        useApi<FavoriteFilmsQuery, FavoriteFilmsQueryVariables>(FavoriteFilmsDocument).bind(null, variables),
        options
    );
useFavoriteFilmsQuery.document = FavoriteFilmsDocument;

useFavoriteFilmsQuery.getKey = (variables?: FavoriteFilmsQueryVariables) =>
    variables === undefined ? ['favoriteFilms'] : ['favoriteFilms', variables];
export const FestivalFilmDetailDocument = `
    query FestivalFilmDetail($id: ID!, $language: String!) {
  film: currentFestivalFilm(id: $id) {
    ...FestivalFilmDetails
  }
}
    ${FestivalFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultCreditFragmentDoc}
${FilmHitFragmentDoc}`;
export const useFestivalFilmDetailQuery = <TData = FestivalFilmDetailQuery, TError = unknown>(
    variables: FestivalFilmDetailQueryVariables,
    options?: UseQueryOptions<FestivalFilmDetailQuery, TError, TData>
) =>
    useQuery<FestivalFilmDetailQuery, TError, TData>(
        ['FestivalFilmDetail', variables],
        useApi<FestivalFilmDetailQuery, FestivalFilmDetailQueryVariables>(FestivalFilmDetailDocument).bind(
            null,
            variables
        ),
        options
    );
useFestivalFilmDetailQuery.document = FestivalFilmDetailDocument;

useFestivalFilmDetailQuery.getKey = (variables: FestivalFilmDetailQueryVariables) => ['FestivalFilmDetail', variables];
export const FestivalSectionDetailDocument = `
    query FestivalSectionDetail($id: ID!, $language: String!) {
  section(id: $id) {
    id
    name
    description {
      key
      translation(language: $language)
    }
    items {
      ... on Composition {
        __typename
        id
        fullTitle
        sortedTitle
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
      ... on Film {
        __typename
        isDocLab
        id
        fullPreferredTitle
        sortedTitle
        docschoolOneliner {
          translation
        }
        logline
        description {
          translation(language: $language)
        }
        intro {
          translation(language: $language)
        }
        edition {
          ...DefaultEdition
        }
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
        credits {
          fullName
          role {
            translation
            key
          }
        }
        yearOfProduction
        lengthInMinutes
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useFestivalSectionDetailQuery = <TData = FestivalSectionDetailQuery, TError = unknown>(
    variables: FestivalSectionDetailQueryVariables,
    options?: UseQueryOptions<FestivalSectionDetailQuery, TError, TData>
) =>
    useQuery<FestivalSectionDetailQuery, TError, TData>(
        ['FestivalSectionDetail', variables],
        useApi<FestivalSectionDetailQuery, FestivalSectionDetailQueryVariables>(FestivalSectionDetailDocument).bind(
            null,
            variables
        ),
        options
    );
useFestivalSectionDetailQuery.document = FestivalSectionDetailDocument;

useFestivalSectionDetailQuery.getKey = (variables: FestivalSectionDetailQueryVariables) => [
    'FestivalSectionDetail',
    variables,
];
export const FestivalSectionsDocument = `
    query festivalSections {
  currentFestivalSections {
    id
    name
    publications {
      favoriteImage {
        ...DefaultApiImage
      }
      stills {
        ...DefaultApiImage
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useFestivalSectionsQuery = <TData = FestivalSectionsQuery, TError = unknown>(
    variables?: FestivalSectionsQueryVariables,
    options?: UseQueryOptions<FestivalSectionsQuery, TError, TData>
) =>
    useQuery<FestivalSectionsQuery, TError, TData>(
        variables === undefined ? ['festivalSections'] : ['festivalSections', variables],
        useApi<FestivalSectionsQuery, FestivalSectionsQueryVariables>(FestivalSectionsDocument).bind(null, variables),
        options
    );
useFestivalSectionsQuery.document = FestivalSectionsDocument;

useFestivalSectionsQuery.getKey = (variables?: FestivalSectionsQueryVariables) =>
    variables === undefined ? ['festivalSections'] : ['festivalSections', variables];
export const FutureTicketsDocument = `
    query futureTickets {
  viewer {
    futureShowTickets {
      name
      qrCode
      show {
        fullTitle
        startOn
        endOn
        location
      }
    }
  }
}
    `;
export const useFutureTicketsQuery = <TData = FutureTicketsQuery, TError = unknown>(
    variables?: FutureTicketsQueryVariables,
    options?: UseQueryOptions<FutureTicketsQuery, TError, TData>
) =>
    useQuery<FutureTicketsQuery, TError, TData>(
        variables === undefined ? ['futureTickets'] : ['futureTickets', variables],
        useApi<FutureTicketsQuery, FutureTicketsQueryVariables>(FutureTicketsDocument).bind(null, variables),
        options
    );
useFutureTicketsQuery.document = FutureTicketsDocument;

useFutureTicketsQuery.getKey = (variables?: FutureTicketsQueryVariables) =>
    variables === undefined ? ['futureTickets'] : ['futureTickets', variables];
export const GuestlistExportUrlDocument = `
    query guestlistExportUrl($filters: [GuestbookFilterInput!]) {
  searchGuestbook(
    query: ""
    filters: $filters
    limit: 1
    offset: 0
    orderBy: COMPANY_NAME_ASC
  ) {
    exportUrl
  }
}
    `;
export const useGuestlistExportUrlQuery = <TData = GuestlistExportUrlQuery, TError = unknown>(
    variables?: GuestlistExportUrlQueryVariables,
    options?: UseQueryOptions<GuestlistExportUrlQuery, TError, TData>
) =>
    useQuery<GuestlistExportUrlQuery, TError, TData>(
        variables === undefined ? ['guestlistExportUrl'] : ['guestlistExportUrl', variables],
        useApi<GuestlistExportUrlQuery, GuestlistExportUrlQueryVariables>(GuestlistExportUrlDocument).bind(
            null,
            variables
        ),
        options
    );
useGuestlistExportUrlQuery.document = GuestlistExportUrlDocument;

useGuestlistExportUrlQuery.getKey = (variables?: GuestlistExportUrlQueryVariables) =>
    variables === undefined ? ['guestlistExportUrl'] : ['guestlistExportUrl', variables];
export const MyVolunteerFormsDocument = `
    query myVolunteerForms {
  viewer {
    email
    volunteerForms {
      availableForms {
        title
        url
      }
      myEntries {
        form {
          title
          url
        }
        isSubmitted
      }
    }
  }
}
    `;
export const useMyVolunteerFormsQuery = <TData = MyVolunteerFormsQuery, TError = unknown>(
    variables?: MyVolunteerFormsQueryVariables,
    options?: UseQueryOptions<MyVolunteerFormsQuery, TError, TData>
) =>
    useQuery<MyVolunteerFormsQuery, TError, TData>(
        variables === undefined ? ['myVolunteerForms'] : ['myVolunteerForms', variables],
        useApi<MyVolunteerFormsQuery, MyVolunteerFormsQueryVariables>(MyVolunteerFormsDocument).bind(null, variables),
        options
    );
useMyVolunteerFormsQuery.document = MyVolunteerFormsDocument;

useMyVolunteerFormsQuery.getKey = (variables?: MyVolunteerFormsQueryVariables) =>
    variables === undefined ? ['myVolunteerForms'] : ['myVolunteerForms', variables];
export const RemoveFilmFromFavoritesDocument = `
    mutation removeFilmFromFavorites($input: festivalRemoveFilmFromFavoritesInput!) {
  removeFilmFromFavorites(input: $input) {
    user {
      favoriteFilms {
        ...FavoriteFilm
      }
    }
  }
}
    ${FavoriteFilmFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useRemoveFilmFromFavoritesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RemoveFilmFromFavoritesMutation,
        TError,
        RemoveFilmFromFavoritesMutationVariables,
        TContext
    >
) =>
    useMutation<RemoveFilmFromFavoritesMutation, TError, RemoveFilmFromFavoritesMutationVariables, TContext>(
        ['removeFilmFromFavorites'],
        useApi<RemoveFilmFromFavoritesMutation, RemoveFilmFromFavoritesMutationVariables>(
            RemoveFilmFromFavoritesDocument
        ),
        options
    );
export const RemoveShowFromCalendarDocument = `
    mutation removeShowFromCalendar($input: festivalRemoveShowFromCalendarInput!) {
  removeShowFromCalendar(input: $input) {
    user {
      calendar {
        items {
          ... on CalendarItem {
            ... on Show {
              __typename
              ...CalendarShow
            }
            ... on Ticket {
              __typename
              name
              show {
                ...CalendarShow
              }
            }
            ... on Appointment {
              __typename
              ...CalendarAppointment
            }
          }
        }
      }
    }
  }
}
    ${CalendarShowFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${CompositionHitFragmentDoc}
${CalendarAppointmentFragmentDoc}`;
export const useRemoveShowFromCalendarMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RemoveShowFromCalendarMutation,
        TError,
        RemoveShowFromCalendarMutationVariables,
        TContext
    >
) =>
    useMutation<RemoveShowFromCalendarMutation, TError, RemoveShowFromCalendarMutationVariables, TContext>(
        ['removeShowFromCalendar'],
        useApi<RemoveShowFromCalendarMutation, RemoveShowFromCalendarMutationVariables>(RemoveShowFromCalendarDocument),
        options
    );
export const SearchAtoZFiltersDocument = `
    query searchAtoZFilters($filters: [AtoZFilterInput!]!) {
  searchAtoZ(filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        key
        amount
      }
    }
  }
}
    `;
export const useSearchAtoZFiltersQuery = <TData = SearchAtoZFiltersQuery, TError = unknown>(
    variables: SearchAtoZFiltersQueryVariables,
    options?: UseQueryOptions<SearchAtoZFiltersQuery, TError, TData>
) =>
    useQuery<SearchAtoZFiltersQuery, TError, TData>(
        ['searchAtoZFilters', variables],
        useApi<SearchAtoZFiltersQuery, SearchAtoZFiltersQueryVariables>(SearchAtoZFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchAtoZFiltersQuery.document = SearchAtoZFiltersDocument;

useSearchAtoZFiltersQuery.getKey = (variables: SearchAtoZFiltersQueryVariables) => ['searchAtoZFilters', variables];
export const SearchAtoZHitsDocument = `
    query searchAtoZHits($query: String, $filters: [AtoZFilterInput!]!, $limit: Int!, $offset: Int!) {
  searchAtoZ(query: $query, filters: $filters, limit: $limit, offset: $offset) {
    totalHits
    hits {
      __typename
      ... on Composition {
        ...CompositionHit
        aToZType
      }
      ... on Film {
        ...FilmHit
        aToZType
        sortedTitle
        credits {
          fullName
          role {
            translation
            key
          }
        }
        lengthInMinutes
        yearOfProduction
        sections {
          id
          name
        }
      }
      ... on Show {
        aToZType
        ...CalendarShow
      }
    }
  }
}
    ${CompositionHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${CalendarShowFragmentDoc}`;
export const useSearchAtoZHitsQuery = <TData = SearchAtoZHitsQuery, TError = unknown>(
    variables: SearchAtoZHitsQueryVariables,
    options?: UseQueryOptions<SearchAtoZHitsQuery, TError, TData>
) =>
    useQuery<SearchAtoZHitsQuery, TError, TData>(
        ['searchAtoZHits', variables],
        useApi<SearchAtoZHitsQuery, SearchAtoZHitsQueryVariables>(SearchAtoZHitsDocument).bind(null, variables),
        options
    );
useSearchAtoZHitsQuery.document = SearchAtoZHitsDocument;

useSearchAtoZHitsQuery.getKey = (variables: SearchAtoZHitsQueryVariables) => ['searchAtoZHits', variables];
export const SearchGuestlistFiltersDocument = `
    query searchGuestlistFilters {
  searchGuestbook(
    query: ""
    filters: []
    limit: 0
    offset: 0
    orderBy: FULL_NAME_ASC
  ) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchGuestlistFiltersQuery = <TData = SearchGuestlistFiltersQuery, TError = unknown>(
    variables?: SearchGuestlistFiltersQueryVariables,
    options?: UseQueryOptions<SearchGuestlistFiltersQuery, TError, TData>
) =>
    useQuery<SearchGuestlistFiltersQuery, TError, TData>(
        variables === undefined ? ['searchGuestlistFilters'] : ['searchGuestlistFilters', variables],
        useApi<SearchGuestlistFiltersQuery, SearchGuestlistFiltersQueryVariables>(SearchGuestlistFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchGuestlistFiltersQuery.document = SearchGuestlistFiltersDocument;

useSearchGuestlistFiltersQuery.getKey = (variables?: SearchGuestlistFiltersQueryVariables) =>
    variables === undefined ? ['searchGuestlistFilters'] : ['searchGuestlistFilters', variables];
export const SearchGuestlistHitsDocument = `
    query searchGuestlistHits($filters: [GuestbookFilterInput!], $orderBy: GuestbookOrderEnum!, $query: String, $limit: Int!, $offset: Int!) {
  searchGuestbook(
    query: $query
    filters: $filters
    limit: $limit
    offset: $offset
    orderBy: $orderBy
  ) {
    totalHits
    hits {
      id
      fullName
      sortedFullName
      companyName
      email
      socials {
        title
        value
      }
      website {
        title
        value
      }
      profession {
        key
        translation
      }
      companyProfile
      films {
        id
        fullPreferredTitle
      }
      accomodationStartsOn
      accomodationEndsOn
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      attendanceType
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useSearchGuestlistHitsQuery = <TData = SearchGuestlistHitsQuery, TError = unknown>(
    variables: SearchGuestlistHitsQueryVariables,
    options?: UseQueryOptions<SearchGuestlistHitsQuery, TError, TData>
) =>
    useQuery<SearchGuestlistHitsQuery, TError, TData>(
        ['searchGuestlistHits', variables],
        useApi<SearchGuestlistHitsQuery, SearchGuestlistHitsQueryVariables>(SearchGuestlistHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchGuestlistHitsQuery.document = SearchGuestlistHitsDocument;

useSearchGuestlistHitsQuery.getKey = (variables: SearchGuestlistHitsQueryVariables) => [
    'searchGuestlistHits',
    variables,
];
export const SearchPressIndustryDocument = `
    query SearchPressIndustry($limit: Int!, $offset: Int!) {
  searchOfcFilms(limit: $limit, offset: $offset) {
    totalHits
    hits {
      ofcStartOn
      ofcEndOn
      ...FilmHit
    }
  }
}
    ${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useSearchPressIndustryQuery = <TData = SearchPressIndustryQuery, TError = unknown>(
    variables: SearchPressIndustryQueryVariables,
    options?: UseQueryOptions<SearchPressIndustryQuery, TError, TData>
) =>
    useQuery<SearchPressIndustryQuery, TError, TData>(
        ['SearchPressIndustry', variables],
        useApi<SearchPressIndustryQuery, SearchPressIndustryQueryVariables>(SearchPressIndustryDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchPressIndustryQuery.document = SearchPressIndustryDocument;

useSearchPressIndustryQuery.getKey = (variables: SearchPressIndustryQueryVariables) => [
    'SearchPressIndustry',
    variables,
];
export const SearchScheduleFiltersDocument = `
    query searchScheduleFilters($filters: [ScheduleFilterInput!]!) {
  searchSchedule(filters: $filters) {
    filters {
      filter
      amounts {
        key
        amount
      }
    }
  }
}
    `;
export const useSearchScheduleFiltersQuery = <TData = SearchScheduleFiltersQuery, TError = unknown>(
    variables: SearchScheduleFiltersQueryVariables,
    options?: UseQueryOptions<SearchScheduleFiltersQuery, TError, TData>
) =>
    useQuery<SearchScheduleFiltersQuery, TError, TData>(
        ['searchScheduleFilters', variables],
        useApi<SearchScheduleFiltersQuery, SearchScheduleFiltersQueryVariables>(SearchScheduleFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchScheduleFiltersQuery.document = SearchScheduleFiltersDocument;

useSearchScheduleFiltersQuery.getKey = (variables: SearchScheduleFiltersQueryVariables) => [
    'searchScheduleFilters',
    variables,
];
export const SearchScheduleHitsDocument = `
    query searchScheduleHits($filters: [ScheduleFilterInput!]!) {
  searchSchedule(filters: $filters) {
    totalHits
    hits {
      __typename
      id
      fullTitle
      ticketAvailabilityStatus
      externalSaleLink
      noSale
      validFrom
      validUntil
      isOngoingProgram
      isVideoOnDemand
      startOn
      endOn
      intro {
        key
        translation
      }
      location
      film {
        id
        fullPreferredTitle
        intro {
          key
          translation
        }
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
      audience {
        key
        translation
      }
      composition {
        id
        fullTitle
        intro {
          key
          translation
        }
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
          stills {
            ...DefaultApiImage
          }
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useSearchScheduleHitsQuery = <TData = SearchScheduleHitsQuery, TError = unknown>(
    variables: SearchScheduleHitsQueryVariables,
    options?: UseQueryOptions<SearchScheduleHitsQuery, TError, TData>
) =>
    useQuery<SearchScheduleHitsQuery, TError, TData>(
        ['searchScheduleHits', variables],
        useApi<SearchScheduleHitsQuery, SearchScheduleHitsQueryVariables>(SearchScheduleHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchScheduleHitsQuery.document = SearchScheduleHitsDocument;

useSearchScheduleHitsQuery.getKey = (variables: SearchScheduleHitsQueryVariables) => ['searchScheduleHits', variables];
export const TicketStatusDocument = `
    query ticketStatus($fionaId: ID!) {
  ticketAvailabilityStatus(fionaId: $fionaId)
}
    `;
export const useTicketStatusQuery = <TData = TicketStatusQuery, TError = unknown>(
    variables: TicketStatusQueryVariables,
    options?: UseQueryOptions<TicketStatusQuery, TError, TData>
) =>
    useQuery<TicketStatusQuery, TError, TData>(
        ['ticketStatus', variables],
        useApi<TicketStatusQuery, TicketStatusQueryVariables>(TicketStatusDocument).bind(null, variables),
        options
    );
useTicketStatusQuery.document = TicketStatusDocument;

useTicketStatusQuery.getKey = (variables: TicketStatusQueryVariables) => ['ticketStatus', variables];
export const UserCalendarDocument = `
    query userCalendar {
  viewer {
    calendar {
      iCalUrl
      items {
        ...UserCalendarCalendarItem
      }
    }
  }
}
    ${UserCalendarCalendarItemFragmentDoc}
${CalendarShowFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${CompositionHitFragmentDoc}
${CalendarAppointmentFragmentDoc}`;
export const useUserCalendarQuery = <TData = UserCalendarQuery, TError = unknown>(
    variables?: UserCalendarQueryVariables,
    options?: UseQueryOptions<UserCalendarQuery, TError, TData>
) =>
    useQuery<UserCalendarQuery, TError, TData>(
        variables === undefined ? ['userCalendar'] : ['userCalendar', variables],
        useApi<UserCalendarQuery, UserCalendarQueryVariables>(UserCalendarDocument).bind(null, variables),
        options
    );
useUserCalendarQuery.document = UserCalendarDocument;

useUserCalendarQuery.getKey = (variables?: UserCalendarQueryVariables) =>
    variables === undefined ? ['userCalendar'] : ['userCalendar', variables];

import { FC, Fragment } from 'react';
import useAlphabetSearch from '../../hooks/useAlphabetSearch';
import { Flex, Heading, Meta, Note, Pagination, ProgrammeListItem } from 'designsystem';
import ProgrammeListSection from './ProgrammeListSection';
import {
    FormattedTimeRange,
    getMetaForFilmOrProjectHit,
    slugifyTitle,
    stripHtml,
    useGetApiImageProps,
    useTickets,
    FormattedDateTimeRange,
} from 'shared';
import ProgrammeAlphabetFilters from './ProgrammeAlphabetFilters';
import ProgrammeListSkeleton from './ProgrammeListSkeleton';
import { FormattedMessage, useIntl } from 'react-intl';
import useToggleFilmFavorite from '../../hooks/useToggleFilmFavorite';
import useToggleCalendar from '../../hooks/useToggleCalendar';
import camelCase from 'lodash.camelcase';
import showTypeFilterMessages, { ShowTypeMessageKey } from '../../utils/showTypeFilterMessages';

const ProgrammeAlphabetSearch: FC = () => {
    const { hits, hitsLoading, currentPage, totalHits, itemsPerPage, setPage } = useAlphabetSearch();
    const getImgProps = useGetApiImageProps();
    const { toggleFavorite, isFavorited, isLoadingFavorites } = useToggleFilmFavorite();
    const { toggleCalendar, isAddedToCalendar, isLoadingCalendar } = useToggleCalendar();
    const { formatMessage } = useIntl();
    const { onOpenTicket, isLoadingTicket } = useTickets();
    return (
        <>
            <ProgrammeAlphabetFilters />
            {!hitsLoading &&
                hits &&
                Object.keys(hits)
                    .sort()
                    .map(firstLetter => (
                        <ProgrammeListSection key={firstLetter}>
                            <Heading variant={2}>{firstLetter.toUpperCase()}</Heading>
                            {hits[firstLetter]?.length > 0 &&
                                hits[firstLetter].map(hit => {
                                    const messages =
                                        hit.aToZType?.map(
                                            aToZType =>
                                                showTypeFilterMessages[camelCase(aToZType) as ShowTypeMessageKey]
                                        ) ?? [];

                                    return (
                                        <Fragment key={hit.id}>
                                            {hit.__typename === 'Film' && (
                                                <ProgrammeListItem
                                                    key={hit.id}
                                                    name={hit.fullPreferredTitle}
                                                    meta={
                                                        <Meta
                                                            size="s"
                                                            {...getMetaForFilmOrProjectHit({ filmOrProject: hit })}
                                                        />
                                                    }
                                                    description={stripHtml(
                                                        hit.intro?.translation ?? hit.description?.translation
                                                    )}
                                                    image={getImgProps(
                                                        hit.publications?.favoriteImage,
                                                        hit.fullPreferredTitle
                                                    )}
                                                    tags={
                                                        hit.sections &&
                                                        hit.sections.map(section => ({
                                                            key: section.id,
                                                            value: section.name,
                                                        }))
                                                    }
                                                    showCalendarIcon={false}
                                                    toggleFavorite={() => toggleFavorite(hit.id)}
                                                    isFavorited={isFavorited(hit.id)}
                                                    isLoadingFavorite={isLoadingFavorites}
                                                    href={`/film/${hit.id}/${slugifyTitle(hit.fullPreferredTitle)}`}
                                                >
                                                    {messages
                                                        .map((message, index) =>
                                                            message ? formatMessage(message) : hit.aToZType[index]
                                                        )
                                                        .join(', ')}
                                                </ProgrammeListItem>
                                            )}
                                            {hit.__typename === 'Composition' && (
                                                <ProgrammeListItem
                                                    key={hit.id}
                                                    name={hit.fullTitle}
                                                    description={stripHtml(
                                                        hit.intro?.translation ?? hit.description?.translation
                                                    )}
                                                    image={getImgProps(hit.publications?.favoriteImage, hit.fullTitle)}
                                                    showFavoriteIcon={false}
                                                    showCalendarIcon={false}
                                                    href={`/composition/${hit.id}/${slugifyTitle(hit.fullTitle)}`}
                                                >
                                                    {messages
                                                        .map((message, index) =>
                                                            message ? formatMessage(message) : hit.aToZType[index]
                                                        )
                                                        .join(', ')}
                                                </ProgrammeListItem>
                                            )}
                                            {hit.__typename === 'Show' && (
                                                <ProgrammeListItem
                                                    key={hit.id}
                                                    name={hit.fullTitle}
                                                    meta={
                                                        <Meta
                                                            size="s"
                                                            items={[
                                                                {
                                                                    key: 'show-time',
                                                                    value: hit.isVideoOnDemand ? (
                                                                        <FormattedDateTimeRange
                                                                            from={
                                                                                new Date(hit.validFrom ?? hit.startOn)
                                                                            }
                                                                            to={new Date(hit.validUntil ?? hit.endOn)}
                                                                            dateVariant="DATE-LONG"
                                                                            timeVariant="TIME-SHORT"
                                                                        />
                                                                    ) : (
                                                                        <FormattedTimeRange
                                                                            from={new Date(hit.startOn)}
                                                                            to={new Date(hit.endOn)}
                                                                            variant="TIME-SHORT"
                                                                        />
                                                                    ),
                                                                },
                                                                hit.location && {
                                                                    key: 'location',
                                                                    value: hit.location,
                                                                },
                                                            ].filter(Boolean)}
                                                        />
                                                    }
                                                    tags={
                                                        hit.audience && [
                                                            { key: hit.audience.key, value: hit.audience.translation },
                                                        ]
                                                    }
                                                    description={stripHtml(
                                                        hit.intro?.translation ??
                                                            hit.film?.intro?.translation ??
                                                            hit.composition?.intro?.translation
                                                    )}
                                                    image={getImgProps(
                                                        hit.film?.publications?.favoriteImage ??
                                                            hit.composition?.publications?.favoriteImage ??
                                                            hit.composition?.publications?.stills[0],
                                                        hit.fullTitle
                                                    )}
                                                    href={
                                                        hit.composition
                                                            ? `/composition/${hit.composition.id}/${slugifyTitle(
                                                                  hit.composition.fullTitle
                                                              )}`
                                                            : hit.film
                                                            ? `/film/${hit.film.id}/${slugifyTitle(
                                                                  hit.film.fullPreferredTitle
                                                              )}`
                                                            : '#'
                                                    }
                                                    isLoadingTicket={isLoadingTicket}
                                                    externalTicketLink={hit.externalSaleLink}
                                                    onTicketClick={
                                                        hit.ticketAvailabilityStatus !== 'SOLD_OUT' &&
                                                        !hit.noSale &&
                                                        (() =>
                                                            onOpenTicket({
                                                                title: hit.fullTitle,
                                                                ticketId: hit.id,
                                                                ticketType: 'show',
                                                            }))
                                                    }
                                                    soldOut={hit.ticketAvailabilityStatus === 'SOLD_OUT'}
                                                    showCalendarIcon
                                                    toggleCalendar={() => toggleCalendar(hit.id)}
                                                    isLoadingCalendar={isLoadingCalendar}
                                                    addedToCalendar={isAddedToCalendar(hit.id)}
                                                    showFavoriteIcon={hit.film !== null}
                                                    isLoadingFavorite={isLoadingFavorites}
                                                    toggleFavorite={() => toggleFavorite(hit.film.id)}
                                                    isFavorited={isFavorited(hit.film?.id)}
                                                >
                                                    {messages
                                                        .map((message, index) =>
                                                            message ? formatMessage(message) : hit.aToZType[index]
                                                        )
                                                        .join(', ')}
                                                </ProgrammeListItem>
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </ProgrammeListSection>
                    ))}
            {!hitsLoading && Object.keys(hits ?? {}).length === 0 && (
                <Note mt={[6, null, null, 13]}>
                    <FormattedMessage defaultMessage="Geen films gevonden voor deze zoekopdracht." />
                </Note>
            )}
            {!hitsLoading && totalHits > itemsPerPage && (
                <Flex justifyContent="center" mt={[6, null, 9]}>
                    <Pagination
                        currentPage={currentPage - 1}
                        setCurrentPage={(p: number) => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                            setPage(p + 1);
                        }}
                        totalPages={Math.ceil(totalHits / itemsPerPage)}
                    />
                </Flex>
            )}
            {hitsLoading && <ProgrammeListSkeleton />}
        </>
    );
};

export default ProgrammeAlphabetSearch;
